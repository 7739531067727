<div class="mt-2 monitor-device-sipwatch-settings-wrapper panel">
    <mat-tab-group animationDuration="0ms" >  
        <mat-tab label="Status">
            <hr class="y-border" />
            <span style="color:green">Found {{countReg}} Phones with Status 200 OK</span> <span> and </span> <span style="color:red">{{countDereg}} with Status 401 Unauthorized</span>
            <table>
                <tbody>
                    <tr>
                        <th>Event Date/Time</th>
                        <th>SIP Id/ext</th>
                        <th>SIP Name</th>
                        <th>Method</th>
                        <th>Status</th>
                        <th>MSG State</th>
                        <th>Device IP</th>
                        <th>Server IP</th>
                        <th>Port</th>
                        <th>Watched</th>
                        <th>Alert</th>
                        <th>Flow</th>
                        <th>Path</th>
                        <th>Alarm</th>
                    </tr>
                    <tr *ngFor="let element of siptrack;">
                        <td>{{ displayDate(element.ts) }}</td>
                        <td>{{ element.SIPUserId }}</td>
                        <td>{{ element.SIPName }}</td>
                        <td>{{ element.Method }}</td>
                        <td>{{ element.status }}</td>
                        <td>{{ displayMSGState(element.reg_type) }}</td>
                        <td>{{ element.dst }}</td>
                        <td>{{ element.src }}</td>
                        <td>{{ element.sport }}</td>
                        <td>{{ displayWatched(element.watchphone) }}</td>
                        <td>{{ displayAlert(element.alert) }}</td>
                        <td>{{ element.flowstate }}</td>
                        <td>{{ element.tagmark }}</td>
                        <td>{{ displayAlarm(element.alarm) }}</td>
                    </tr>
                    <tr *ngIf="!siptrack || siptrack.length == 0 ">
                        <td style="text-align: center;" colspan="14">No data</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>        
        <mat-tab label="Watched Phones">           
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>SIP Id/ext</th>
                        <th>SIP Name</th>                            
                        <th>SIP Server Port</th>
                        <th>Alert</th>
                        <th>Alarm</th>
                    </tr>
                    <tr *ngFor="let element of watchlists;">
                        <td>{{ element.SIPUserId }}</td>
                        <td>{{ element.SIPUserId }}</td>                            
                        <td>{{ element.SIPServerPort }}</td>
                        <td>{{ displayAlert(element.alert) }}</td>
                        <td>{{ displayAlarm(element.alarm) }}</td>
                    </tr>
                    <tr *ngIf="!watchlists || watchlists.length == 0 ">
                        <td style="text-align: center;" colspan="5">No data</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="Alerts Log">
            <app-device-sipwatch-alert-logs [deviceInfo]="_device"></app-device-sipwatch-alert-logs>
        </mat-tab>  
        <mat-tab label="Config">
            <hr class="y-border" />
            <table>
                <tbody>
                    <tr>
                        <th>Status</th>
                        <th>Delay Start up</th>
                        <th>Clean up detected phone interval</th>
                        <th>State Buffer Size</th>
                        <th>Interface</th>
                        <th>Pattern</th>
                        <th>Port Range</th>
                    </tr>
                    <tr *ngIf="common && sipwatch">
                        <td>{{ displayEnabled(common.enabled) }}</td>
                        <td>{{ common.delay_time }}</td>
                        <td>{{ common.cleanup_time }}</td>
                        <td>{{ common.state_buf_size }}</td>
                        <td>{{ sipwatch.ifname }}</td>
                        <td>{{ sipwatch.pattern }}</td>
                        <td>{{ sipwatch.port_range }}</td>
                    </tr>
                    <tr *ngIf="!common && !sipwatch">
                        <td style="text-align: center;" colspan="7">No data</td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>  
    </mat-tab-group>       
</div>
